<template>
  <section>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="text-center">
        <SiteLogo class="w-16 h-16 mx-auto w-auto rounded" />
      </div>
      <h2
        class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
      >
        Sign in to your account
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <FormKit
          v-model="form"
          type="form"
          :actions="false"
          @submit="handleLogin"
        >
          <div class="space-y-6">
            <p
              class="text-red-500"
              v-if="$route.query.error === 'CredentialsSignin'"
            >
              Your email or password is not correct.
            </p>

            <FormKit
              label="Email address"
              name="email"
              type="email"
              autocomplete="email"
            />
            <FormKit
              name="password"
              label="Password"
              type="password"
              autocomplete="current-password"
            />

            <div v-if="false" class="flex items-center justify-between">
              <div class="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  for="remember-me"
                  class="ml-3 block text-sm leading-6 text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div class="text-sm leading-6">
                <a
                  href="#"
                  class="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Forgot password?
                </a>
              </div>
            </div>

            <div>
              <BaseButton class="w-full text-center" :loading="submitting">
                Login
              </BaseButton>
            </div>
          </div>
        </FormKit>

        <div>
          <div class="relative mt-10">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200"></div>
            </div>
            <div
              class="relative flex justify-center text-sm font-medium leading-6"
            >
              <span class="bg-white px-6 text-gray-900">Or continue with</span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-1 gap-4">
            <a
              v-if="false"
              href="#"
              class="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
            >
              <svg
                class="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path
                  d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                ></path>
              </svg>
              <span class="text-sm font-semibold leading-6">Twitter</span>
            </a>
            <AuthSocialButtons
              :loading="submitting"
              @select="handleProviderLogin"
            />
          </div>
        </div>
      </div>

      <p class="mt-10 text-center text-sm text-gray-500">
        Not a member?
        <!-- space -->
        <NuxtLink
          href="/register"
          class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Create a free account
        </NuxtLink>
      </p>
      <p class="mt-4 text-center text-sm text-gray-500">
        <NuxtLink
          href="/users/forgot-password"
          class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Forgot your password?
        </NuxtLink>
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
useHead({
  title: 'Login - PromptDrive',
  htmlAttrs: {
    class: 'h-full bg-gray-50',
  },
  bodyAttrs: {
    class: 'h-full',
  },
})

definePageMeta({
  layout: 'auth',
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/prompts/folders/all',
  },
})

const submitting = ref(false)
const { signIn } = useAuth()
const router = useRoute()
const route = useRoute()

const form = ref({
  email: '',
  password: '',
})

const handleProviderLogin = async ({ provider }: { provider: any }) => {
  submitting.value = true

  await signIn(provider?.name.toLocaleLowerCase(), {
    callbackUrl: (route.query.callbackUrl as string) || '/prompts/folders/all',
  })

  submitting.value = false
}

const handleLogin = async () => {
  submitting.value = true

  await signIn('credentials', {
    email: form.value.email,
    password: form.value.password,
    callbackUrl: (route.query.callbackUrl as string) || '/prompts/folders/all',
  })

  submitting.value = false
}
</script>
